.table-container {
  height: 620px;
  width: 100%;
  position: relative;
  padding-top: 0rem;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0.7rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.stickyHeader {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  align-self: flex-end;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 50;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  border-bottom: rgb(234, 233, 233) solid 1px;
  label {
    font-weight: 500;
  }
  .label {
    font-weight: 800;
    font-size: 1.5rem;
  }
}

.table-cell-border {
  border: 1px solid rgb(241, 239, 239);
}
