.popup-style {
  min-width: 300px; /* Adjust as needed */
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.popup-content {
  padding: 5px;
}

.popup-content h3 {
  margin: 0 0 5px;
  font-size: 18px;
  font-weight: bold;
}

.popup-content table {
  width: 100%;
  border-collapse: collapse;
}

.popup-content table td {
  padding: 4px;
}

.popup-content table td:first-child {
  font-weight: bold;
}

.popup-content table td:last-child {
  text-align: left;
}

.map-container {
  max-width: 700px;
  height: 608px;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 0.7rem;
  border: 0.063rem solid rgb(224, 224, 224);
}

.legendContainer {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  position: absolute;
  bottom: 30px;
  left: 10px;
  border-radius: 5px;
  font-size: 12px;
  color: black;
  z-index: 10;
}

.legendTitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.legendGradient {
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
}

.legendLabels {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}
