.filter-buttons {
  display: flex;
  justify-content: end;
  margin-top: 1rem !important;
  .clear-button {
    width: 10rem;
    border: 1px black solid !important;
    background-color: white;
    margin-right: 2.5rem;
    width: 16rem;
    color: black;
    font-weight: 800;
    font-size: 2rem;
    min-height: 3rem;
  }
  .filter-button {
    height: fit-content;
    font-weight: 800;
    font-size: 2rem;
    width: 16rem;
    min-height: 3rem;
  }
}

.filters {
  border-radius: 0.7rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.filter-wrapper {
}
